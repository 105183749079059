<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields(GOODS_QUALITY,zoneTemperature)"
      @submit="handleQuery"
      @reset="resetQuery"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="searchQuery.shipperId"
        />
      </template>
      <template #warehouseAreaId>
        <el-select
          v-model="searchQuery.warehouseAreaId"
          clearable
          filterable
        >
          <el-option
            v-for="item in warehouseZonelist"
            :key="item.id"
            :label="item.zoneName"
            :value="item.id"
          />
        </el-select>
      </template>
      <template #labelSelect>
        <select-label
          v-model:labelSelectData="labelSelectData"
          :label-options="attributeCodes"
        />
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="tableCol"
      :checked-columns="defaultCheckedCol"
      show-summary
      :show-table-setting="true"
      :summary-method="handleGetSummaries"
      @selection-change="handleSelectionChange"
      @update:pagination="handlePaginationChange"
      @columnsChange="handleColumnsProp"
    >
      <template #tableHeaderRight>
        <nh-button
          :loading="loading.handleExport"
          :track="{
            trackName: '在库管理/库存管理/库位批次库存查询/[导出]'
          }"
          @click="handleExport"
        >
          导出
        </nh-button>
        <nh-button
          :track="{
            trackName: '在库管理/库存管理/库位批次库存查询/[调整]'
          }"
          @click="handleAdjustment"
        >
          调整
        </nh-button>
        <nh-button
          :track="{
            trackName: '在库管理/库存管理/库位批次库存查询/[一键移库]'
          }"
          @click="handleTransferLibrary"
        >
          一键移库
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleRecordsClick(row)">
          预占记录
        </nh-button>
        <nh-button type="text" @click="supplementaryPrinting(row)">
          补打标签
        </nh-button>
      </template>
    </pltable>
  </pl-block>
  <recordsDialog ref="recordsDialog" />
  <inventory-adjustment ref="inventoryAdjustment" @submitSuccess="getTableData" />
  <library-transfer ref="libraryTransfer" @submitSuccess="handleQuery" />
</template>
<script>
import tableSummary from '@/utils/tableSummary';

import loadingMixin from '@thales/loading';
import { SearchType } from '@/constant/form';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import { customerShowList } from '../location-batch-query/api';

import {
  containerStockPage, containerStockExport, warehouseZonePage, batchFieldList,
  byContainerStock,
} from './api';
import { ATTRIBUTE_TYPE } from './constant';
import selectLabel from '../../components/selectLabel.vue';
import inventoryAdjustment from './inventory-adjustment/index.vue';
import libraryTransfer from './library-transfer/index.vue';
import summaryConfig from './config';
import recordsDialog from './subPages/recordsDialog.vue';

export default {
  name: 'LocationBatchQuery',
  components: {
    recordsDialog,
    selectLabel,
    inventoryAdjustment,
    libraryTransfer,
  },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      SearchType,
      tableData: [],
      searchQuery: {
        shipperId: null,
        batchAttrName: '',
        batchAttrValue: '',
      },
      loading: {
        getTableData: false,
        handleExport: false,
      },
      selectIds: [],
      selectList: [],
      attributeCodes: [],
      labelSelectData: {
        labelKey: '',
        labelValue: '',
      },
      batchField: [],
      selectCol: [],
      defaultCheckedCol: [],
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
    tableCol() {
      return TABLECOLUMN(this.batchField);
    },
  },
  created() {
    this.getWarehouseZonelist();
    this.loadBatchFieldList();
    this.getTableData();
    this.getBatchField();
    this.defaultCheckedCol = (TABLECOLUMN() || []).map((item) => item.prop || item.type);
  },
  methods: {
    async getBatchField() {
      this.batchField = await customerShowList();
    },
    // 选择的字段
    handleColumnsProp(col) {
      this.selectCol = col;
    },
    async getWarehouseZonelist(word) {
      const resp = await warehouseZonePage({ page: 1, size: 1000 }, { zoneName: word || '' });
      this.warehouseZonelist = resp.records;
    },
    async loadBatchFieldList() {
      let result = await batchFieldList({});
      result = result || [];
      this.attributeCodes = result.map((item) => ({
        ...item,
        label: item.fieldName,
        value: item.fieldCode,
        prop: item.fieldCode,
        type: item.component === ATTRIBUTE_TYPE.DATE
          ? this.SearchType.DATE_PICKER : this.SearchType.INPUT,
        props: {
          clearable: true,
        },
      }));
      this.labelSelectData.labelKey = this.attributeCodes[0].value;
    },
    formatBatchField(code, batchAttributes) {
      return batchAttributes ? batchAttributes[code] : '';
    },
    handleGetSummaries({ columns, data }) {
      const config = summaryConfig;
      const sums = [];
      columns.forEach((column, index) => {
        sums[index] = tableSummary({ column, data, config });
      });
      return sums;
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    resetQuery() {
      this.labelSelectData.labelValue = '';
      this.labelSelectData.labelKey = this.attributeCodes[0].value;
      this.handleQuery();
    },
    async getTableData() {
      this.selectIds = [];
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await containerStockPage(pagination, params);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    handleSelectionChange(selection) {
      this.selectList = selection;
      this.selectIds = selection.map((item) => item.id);
    },
    buildParams() {
      this.searchQuery.batchAttrName = this.labelSelectData.labelKey;
      this.searchQuery.batchAttrValue = this.labelSelectData.labelValue;
      return {
        ...this.searchQuery,
      };
    },
    handlePaginationChange() {
      this.getTableData();
    },
    async handleExport() {
      const data = this.buildParams();
      // 导出时以选择的字段为准
      const exportCol = this.selectCol.length > 0 ? this.selectCol : this.defaultCheckedCol;
      const exportFields = exportCol.reduce((prev, curr) => {
        if (curr !== 'selection' && curr !== 'index' && curr !== 'action') {
          const findField = this.tableCol.find((item) => item.prop === curr) || {};
          return [...prev, {
            fieldCode: findField.exportProp || findField.prop,
            fieldName: findField.label,
          }];
        }
        return prev;
      }, []);
      // TODO:导出功能原设计有选择导出，但后端未实现，暂时阉割
      // if (this.selectIds.length > 0) {
      //   data.selectIds = this.selectIds;
      // }
      await containerStockExport({ ...data, exportFields });
    },
    handleAdjustment() {
      if (this.selectIds.length === 0 || this.selectIds.length > 1) {
        this.$message({
          message: '请选择其中一条数据',
          type: 'warning',
        });
        return;
      }
      this.$refs.inventoryAdjustment.init(this.selectList[0]);
    },
    handleTransferLibrary() {
      if (this.selectList.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条库存' });
        return;
      }
      this.$refs.libraryTransfer.init(this.selectList);
    },
    handleRecordsClick(row) {
      this.$refs.recordsDialog.open(row);
    },
    async supplementaryPrinting(row) {
      const data = await byContainerStock({
        stockIds: row.id,
      });
      const tableLists = data.map((item) => ({
        ...item,
      }));
      const routeUrl = this.$router.resolve({
        path: '/print/location-and-container',
        query: { tableLists: JSON.stringify(tableLists), printNum: 1 },
      });
      window.open(routeUrl.href, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.batch-field {
  text-indent: 12px;
  padding: 4px 0;
}
</style>
