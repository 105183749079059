import Moment from '@/utils/moment';
import { TaskTypeText, BillTypeText } from '@/constant/task';

export const orderInfoFileds = [
  {
    prop: 'goodsCode',
    label: '货品编码:',
  },
  {
    prop: 'shipperName',
    label: '货主:',
  },
  {
    prop: 'goodsName',
    label: '货品名称:',
  },
  {
    prop: 'shipperGoodsCode',
    label: '货主货品编码:',
  },
  {
    prop: 'batchNo',
    label: '批次号:',
  },
  {
    prop: 'locationCode',
    label: '库位:',
  },
  {
    prop: 'lockedAmount',
    label: '锁定量:',
  },
];

export const tableColumns = [
  {
    label: '任务号',
    prop: 'taskNo',
  },
  {
    label: '任务类型',
    prop: 'taskType',
    formatter: (row) => TaskTypeText[row.taskType],
  },
  {
    label: '来源单据类型',
    prop: 'orderType',
    formatter: (row) => BillTypeText[row.orderType],
  },
  {
    label: '来源单据号',
    prop: 'orderNo',
  },
  {
    label: '锁定量',
    prop: 'lockedAmountStr',
  },
  {
    label: '锁定时间',
    prop: 'lockedTime',
    formatter: (row) => Moment.format(row.lockedTime),
  },
];
