import { SearchType } from '@/constant/form';
// import { DateTime } from '@/constant/tableConfig';

export const ADJUST_TYPE = [
  {
    label: '数量调整',
    value: 'AMOUNT_ADJUST',
  },
  {
    label: '批次调整',
    value: 'BATCH_NO_ADJUST',
  },
  {
    label: '盘点调整',
    value: 'INVENTORY_ADJUST',
  },
  {
    label: '其他调整',
    value: 'OTHER_ADJUST',
  },
];
export const ADJUST_TYPE_EN = {
  AMOUNT_ADJUST: 'AMOUNT_ADJUST',
  BATCH_NO_ADJUST: 'BATCH_NO_ADJUST',
  INVENTORY_ADJUST: 'INVENTORY_ADJUST',
  OTHER_ADJUST: 'OTHER_ADJUST',
};
export const ADJUST_TYPE_CN = {
  AMOUNT_ADJUST: '数量调整',
  BATCH_NO_ADJUST: '批次调整',
  INVENTORY_ADJUST: '盘点调整',
  OTHER_ADJUST: '其他调整',
};
export const SOURCE_TYPE = [
  {
    label: '盘点单',
    value: 'INVENTORY',
  },
  {
    label: '手动添加',
    value: 'MANUAL_ADD',
  },
];
export function BATCH_NO_COLUMN(batchAttribute) {
  return [
    {
      label: '批次号',
      prop: 'batchNo',
      noEdit: true,
      minWidth: 180,
    },
    ...batchAttribute,
    {
      label: '库存量',
      prop: 'amount',
      minWidth: 240,
    },
    {
      label: '容器号',
      prop: 'containerCode',
      minWidth: 100,
      type: SearchType.INPUT,
    },
  ];
}

export function OTHER_NO_COLUMN() {
  return [
    {
      label: '容器号',
      prop: 'containerCode',
      minWidth: 100,
    },
    {
      label: '货品质量',
      prop: 'goodsQuality',
      minWidth: 100,
    },
    {
      label: '库存量',
      prop: 'amount',
      minWidth: 160,
    },
    {
      label: '辅助数量',
      prop: 'auxiliaryAmount',
      minWidth: 100,
    },
  ];
}
