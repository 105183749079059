<template>
  <div class="adjustment">
    <nh-dialog
      v-model="dialogVisible"
      title="新增库存调整"
      custom-class="custom-dialog_1200"
      :track="{
        name_zh: `在库管理/库存管理/容器库存查询/弹窗-调整`,
        trackName: $route.path
      }"
      @close="handleBeforeClose"
    >
      <nh-title-text title="货品信息" />
      <div class="content-box">
        <el-row>
          <el-col :span="8">
            货品编码:{{ infoData.goodsCode }}
          </el-col>
          <el-col :span="8">
            货品名称:{{ infoData.goodsName }}
          </el-col>
          <el-col :span="8">
            货主货品编码:{{ infoData.shipperGoodsCode }}
          </el-col>
          <el-col :span="8">
            货主:{{ infoData.shipperName }}
          </el-col>
          <el-col :span="8">
            库位:{{ infoData.locationCode }}
          </el-col>
          <el-col :span="8">
            温层:{{ infoData.temperatureLayerName }}
          </el-col>
          <el-col :span="8">
            库存数量:{{ AvailableAmount(infoData) }}
          </el-col>
          <el-col :span="8">
            批次号:{{ infoData.batchNo }}
          </el-col>
          <el-col :span="8">
            容器:{{ infoData.containerCode }}
          </el-col>
        </el-row>
      </div>
      <el-radio
        v-model="infoData.adjustType"
        class="radio-title"
        :label="ADJUST_TYPE_EN.AMOUNT_ADJUST"
        @change="radioChange"
      >
        数量调整
      </el-radio>
      <div class="content-box">
        <div v-if="infoData.adjustType === ADJUST_TYPE_EN.AMOUNT_ADJUST">
          <el-row>
            <el-col :span="8">
              库存数量:{{ AvailableAmount(infoData.adjustInfos[0]) }}
            </el-col>
            <el-col :span="12">
              调整为
              <el-input-number
                v-model="infoData.adjustInfos[1].bigUnitAmount"
                :min="0"
                size="small"
                :step="infoData.bigUnit === infoData.smallUnit?
                  STEPS_NUM.the_same:STEPS_NUM.not_same"
                :precision="infoData.bigUnit === infoData.smallUnit? 0 : 3"
                class="amount-box"
                step-strictly
                :controls="false"
              />
              <span class="unit-box">{{ infoData.bigUnit }}</span>
              <el-input-number
                v-model="infoData.adjustInfos[1].smallUnitAmount"
                :min="0"
                class="amount-box"
                :step="0.001"
                :precision="3"
                size="small"
                step-strictly
                :controls="false"
              />
              <span class="unit-box">{{ infoData.smallUnit }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              库存辅助数量:{{ infoData.auxiliaryAmount + infoData.auxiliaryUnit }}
            </el-col>
            <el-col :span="12">
              调整为
              <el-input-number
                v-model="infoData.adjustInfos[1].auxiliaryAmount"
                :min="0"
                size="small"
                :step="0.001"
                :precision="3"
                :disabled="!infoData.copyCode"
                step-strictly
                :controls="false"
              />
              <span class="unit-box">{{ infoData.auxiliaryUnit }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-radio
        v-model="infoData.adjustType"
        class="radio-title"
        :label="ADJUST_TYPE_EN.BATCH_NO_ADJUST"
        @change="radioChange"
      >
        批次调整
      </el-radio>
      <div class="content-box">
        <div v-if="infoData.adjustType === ADJUST_TYPE_EN.BATCH_NO_ADJUST">
          <el-table :data="infoData.adjustInfos">
            <el-table-column
              v-for="(item,index) in BATCH_NO_COLUMN(batchAttribute)"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              :min-width="item.minWidth"
            >
              <template #default="scope">
                <span v-if="scope.$index === 0">
                  <span v-if="item.prop === 'amount'">
                    {{ AvailableAmount(scope.row) }}
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </span>
                <span v-else>
                  <div v-if="!item.noEdit && item.prop !== 'amount'">
                    <component
                      :is="`el-${item.type}`"
                      v-if="NoSlotComponents.includes(`el-${item.type}`)"
                      v-model="scope.row[item.prop]"
                      v-bind="item.props"
                      :disabled-date="(date) => handleDisbaledDate(date, scope.$index, item)"
                      @change="handleTableChange(scope.$index, item)"
                    />
                    <component
                      :is="`el-${item.type}`"
                      v-else
                      v-model="scope.row[item.prop]"
                      v-bind="item.props"
                    />
                  </div>
                  <span v-if="item.prop === 'amount'">
                    <el-input-number
                      v-model="scope.row.bigUnitAmount"
                      :min="0"
                      size="small"
                      :step="infoData.bigUnit === infoData.smallUnit?
                        STEPS_NUM.the_same:STEPS_NUM.not_same"
                      :precision="infoData.bigUnit === infoData.smallUnit? 0 : 3"
                      class="amount-box"
                      step-strictly
                      :controls="false"
                      @change="(currentValue,oldValue)=>{
                        bigunitAmountChange(currentValue,oldValue,scope.$index)}"
                    />
                    <span class="unit-box">{{ infoData.bigUnit }}</span>
                    <el-input-number
                      v-model="scope.row.smallUnitAmount"
                      :min="0"
                      class="amount-box"
                      :step="0.001"
                      :precision="3"
                      size="small"
                      step-strictly
                      :controls="false"
                      @change="(currentValue,oldValue)=>{
                        smallunitAmountChange(currentValue,oldValue,scope.$index)}"
                    />
                    <span class="unit-box">{{ infoData.smallUnit }}</span>
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="100"
              fixed="right"
            >
              <template #default="scope">
                <el-button
                  v-if="scope.$index>0"
                  type="text"
                  @click="addBatchAdjust"
                >
                  新增
                </el-button>
                <el-button
                  v-if="scope.$index>0 && infoData.adjustInfos.length>2"
                  type="text"
                  @click="delBatchAdjust(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-radio
        v-model="infoData.adjustType"
        class="radio-title"
        :label="ADJUST_TYPE_EN.OTHER_ADJUST"
        @change="radioChange"
      >
        其他调整
      </el-radio>
      <div v-if="infoData.adjustType === ADJUST_TYPE_EN.OTHER_ADJUST" class="content-box">
        <div v-if="infoData.adjustType === ADJUST_TYPE_EN.OTHER_ADJUST">
          <el-table :data="infoData.adjustInfos">
            <el-table-column
              v-for="(item,index) in OTHER_NO_COLUMN()"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :min-width="item.minWidth"
            >
              <template #default="scope">
                <span v-if="scope.$index === 0">
                  <span v-if="item.prop === 'amount'">
                    {{ AvailableAmount(scope.row) }}
                  </span>
                  <span v-else-if="item.prop === 'auxiliaryAmount'">
                    {{ scope.row[item.prop] }}{{ infoData.auxiliaryUnit }}
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </span>

                <span v-else>
                  <div
                    v-if="item.prop === 'containerCode'"
                    class="table-input"
                  >
                    <el-input v-model="scope.row[item.prop]" />
                    <span v-if="item.prop === 'auxiliaryAmount'" style="width: 40px;">
                      {{ infoData.auxiliaryUnit }}
                    </span>
                  </div>

                  <el-select
                    v-if="item.prop === 'goodsQuality'"
                    v-model="scope.row.goodsQuality"
                  >
                    <el-option
                      v-for="it in GOODS_QUALITY"
                      :key="it.value"
                      :label="it.label"
                      :value="it.value"
                    />
                  </el-select>

                  <span v-if="item.prop === 'amount'">
                    <el-input-number
                      v-model="scope.row.bigUnitAmount"
                      :min="0"
                      size="small"
                      class="amount-box"
                      :step="infoData.bigUnit === infoData.smallUnit?
                        STEPS_NUM.the_same:STEPS_NUM.not_same"
                      :precision="infoData.bigUnit === infoData.smallUnit? 0 : 3"
                      step-strictly
                      :controls="false"
                      @change="(currentValue,oldValue)=>{
                        bigunitAmountChange(currentValue,oldValue,scope.$index)}"
                    />
                    <span class="unit-box">{{ infoData.bigUnit }}</span>
                    <el-input-number
                      v-model="scope.row.smallUnitAmount"
                      :min="0"
                      class="amount-box"
                      size="small"
                      :step="0.001"
                      :precision="3"
                      step-strictly
                      :controls="false"
                      @change="(currentValue,oldValue)=>{
                        smallunitAmountChange(currentValue,oldValue,scope.$index)}"
                    />
                    <span class="unit-box">{{ infoData.smallUnit }}</span>
                  </span>

                  <div
                    v-if="item.prop === 'auxiliaryAmount'"
                    class="table-input"
                  >
                    <el-input-number
                      v-model="scope.row.auxiliaryAmount"
                      :min="0"
                      size="small"
                      :step="0.001"
                      :precision="3"
                      step-strictly
                      :disabled="!infoData.copyCode"
                      :controls="false"
                      @change="(currentValue,oldValue)=>{
                        auxiliaryAmountChange(currentValue,oldValue,scope.$index)}"
                    />
                    <span style="width: 40px;">
                      {{ infoData.auxiliaryUnit }}
                    </span>
                  </div>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="130"
              fixed="right"
            >
              <template #default="scope">
                <el-button
                  v-if="scope.$index>0"
                  type="text"
                  @click="addBatchAdjust"
                >
                  新增
                </el-button>
                <el-button
                  v-if="scope.$index>0 && infoData.adjustInfos.length>2"
                  type="text"
                  @click="delBatchAdjust(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <template #footer>
        <nh-button
          type="primary"
          @click="handleClose"
        >
          取 消
        </nh-button>
        <nh-button
          type="primary"
          :loading="loading.handleSubmit"
          @click="handleSubmit"
        >
          确 定
        </nh-button>
      </template>
    </nh-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { SearchType, NoSlotComponents } from '@/constant/form';
import unitNumber from '@/utils/unitNumber';
import { YMD } from '@/constant/timeFormat';

import {
  ADJUST_TYPE_EN, ADJUST_TYPE_CN, BATCH_NO_COLUMN, OTHER_NO_COLUMN, SOURCE_TYPE,
} from './fileds';
import { STEPS_NUM, ATTRIBUTE_TYPE } from '../constant';
import { stockAdjustCreate, getBatchAttribute } from '../api';

export default {
  emits: ['submitSuccess'],
  data() {
    return {
      ADJUST_TYPE_EN,
      ADJUST_TYPE_CN,
      SOURCE_TYPE,
      BATCH_NO_COLUMN,
      OTHER_NO_COLUMN,
      SearchType,
      STEPS_NUM,
      ATTRIBUTE_TYPE,
      YMD,
      NoSlotComponents,
      dialogVisible: false,
      infoData: {},
      batchAttribute: [],
      loading: {
        handleSubmit: false,
      },
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    init(data) {
      this.dialogVisible = true;
      this.infoData = { ...data };
      const {
        id,
        batchAttributes,
        batchNo,
        totalBigUnitAmount,
        totalSmallUnitAmount,
        auxiliaryAmount,
        containerCode,
        goodsQuality,
        bigUnitAvailableAmount,
        smallUnitAvailableAmount,
        bigUnit,
        smallUnit,
      } = this.infoData;
      this.infoData.adjustInfos = [{
        ...batchAttributes,
        batchNo,
        bigUnitAmount: totalBigUnitAmount,
        smallUnitAmount: totalSmallUnitAmount,
        auxiliaryAmount,
        containerCode,
        goodsQuality,
        bigUnitAvailableAmount,
        smallUnitAvailableAmount,
        bigUnit,
        smallUnit,
      }, {}];
      this.getBatchAttribute(id);
    },
    async getBatchAttribute(id) {
      const result = await getBatchAttribute({ stockId: id });
      this.batchAttribute = result.map((item) => ({
        ...item,
        label: item.fieldName,
        prop: item.fieldCode,
        width: '140px',
        type: item.component === ATTRIBUTE_TYPE.DATE
          ? this.SearchType.DATE_PICKER : this.SearchType.INPUT,
        props: {
          clearable: true,
          valueFormat: YMD,
          editable: false,
        },
      }));
    },
    AvailableAmount(infoData) {
      return infoData.bigUnitAvailableAmount
      + infoData.bigUnit
      + infoData.smallUnitAvailableAmount
      + infoData.smallUnit;
    },
    addBatchAdjust() {
      if (this.infoData.adjustType === ADJUST_TYPE_EN.BATCH_NO_ADJUST) {
        this.infoData.adjustInfos.push({
          ...this.infoData.batchAttributes,
          containerCode: this.infoData.batchAttributes?.containerCode
            || this.infoData.containerCode,
        });
      } else {
        this.infoData.adjustInfos.push({});
      }
    },
    radioChange() {
      if (this.infoData.adjustType === ADJUST_TYPE_EN.BATCH_NO_ADJUST) {
        this.infoData.adjustInfos = [
          { ...this.infoData.adjustInfos[0] },
          {
            ...this.infoData.batchAttributes,
            containerCode: this.infoData.batchAttributes?.containerCode
            || this.infoData.containerCode,
          }];
      } else {
        this.infoData.adjustInfos = [{ ...this.infoData.adjustInfos[0] }, {}];
      }
      this.infoData.adjustInfos[0].bigUnitAvailableAmount = this.infoData.bigUnitAvailableAmount;
      this.infoData.adjustInfos[0]
        .smallUnitAvailableAmount = this.infoData.smallUnitAvailableAmount;
    },
    delBatchAdjust(row) {
      const index = this.infoData.adjustInfos.indexOf(row);
      if (index !== -1) {
        this.infoData.adjustInfos[0].smallUnitAvailableAmount += row.smallUnitAmount || 0;
        this.infoData.adjustInfos[0].bigUnitAvailableAmount += row.bigUnitAmount || 0;
        this.infoData.adjustInfos[0].auxiliaryAmount += row.auxiliaryAmount || 0;
        this.infoData.adjustInfos.splice(index, 1);
      }
    },
    handleBeforeClose() {
      this.infoData = {};
    },
    buildParams() {
      const adjustInfos = this.infoData.adjustInfos.map((item) => {
        const batchAttributes = {};
        const adjustAttribute = {
          newBigUnitAmount: item.bigUnitAmount,
          newSmallUnitAmount: item.smallUnitAmount,
          newAuxiliaryAmount: item.auxiliaryAmount,
          newContainerCode: item.containerCode,
          newGoodsQuality: item.goodsQuality,
        };
        if ((adjustAttribute.newSmallUnitAmount || adjustAttribute.newSmallUnitAmount === 0)
        && !adjustAttribute.newBigUnitAmount) {
          adjustAttribute.newBigUnitAmount = 0;
        }
        if (!adjustAttribute.newSmallUnitAmount && (adjustAttribute.newBigUnitAmount
        || adjustAttribute.newBigUnitAmount === 0)) {
          adjustAttribute.newSmallUnitAmount = 0;
        }
        this.batchAttribute.forEach((it) => { batchAttributes[it.prop] = item[it.prop]; });
        return { batchAttributes, adjustAttribute };
      });
      adjustInfos.splice(0, 1);
      const data = {
        stockId: this.infoData.id,
        adjustType: this.infoData.adjustType,
        sourceType: this.SOURCE_TYPE[1].value,
        adjustInfos,
      };
      return data;
    },
    async handleSubmit() {
      const data = this.buildParams();
      // 如果调整批属性
      if (this.infoData.adjustType === ADJUST_TYPE_EN.BATCH_NO_ADJUST) {
        // 判断是否全部已填写
        const isFillInAll = data.adjustInfos
          .every((item) => Object.values(item.batchAttributes).every((it) => it));
          // 有一项未填写就报错
        if (!isFillInAll) {
          this.$message({ type: 'warning', message: '请填写完整所有批属性内容' });
          return;
        }
      }
      await stockAdjustCreate(data);
      this.$message({ type: 'success', message: '调整成功' });
      this.handleClose();
      this.$emit('submitSuccess');
    },
    // 计算剩余可移库库存总量
    calculateOldTotal() {
      const oldTotalParams = {
        bigUnit: this.infoData.smallUnit,
        smallUnit: this.infoData.smallUnit,
        smallUnitNumber: this.infoData.smallUnitAvailableAmount,
        bigUnitNumber: this.infoData.bigUnitAvailableAmount,
        conversionRate: this.infoData.conversionRate,
      };
      return unitNumber.getTotal(oldTotalParams);
    },
    // 计算当前移动库存总量
    calculateNewTotal() {
      const newTotalParams = {
        bigUnit: this.infoData.smallUnit,
        smallUnit: this.infoData.smallUnit,
        smallUnitNumber: this.calculateSmallTotal(),
        bigUnitNumber: this.calculateBigTotal(),
        conversionRate: this.infoData.conversionRate,
      };
      return unitNumber.getTotal(newTotalParams);
    },
    // 计算列表零单位总量
    calculateSmallTotal() {
      let total = 0;
      this.infoData.adjustInfos.forEach((item, index) => {
        if (index !== 0) {
          total += (item.smallUnitAmount || 0);
        }
      });
      return total;
    },
    // 计算列表整单位总量
    calculateBigTotal() {
      let total = 0;
      this.infoData.adjustInfos.forEach((item, index) => {
        if (index !== 0) {
          total += (item.bigUnitAmount || 0);
        }
      });
      return total;
    },
    // 计算列表整单位总量
    calculateAuxiliaryTotal() {
      let total = 0;
      this.infoData.adjustInfos.forEach((item, index) => {
        if (index !== 0) {
          total += (item.auxiliaryAmount || 0);
        }
      });
      return total;
    },
    // 差异量转化为整零数量
    differenceCalc(differenceAmount) {
      const differenceParams = {
        bigUnit: this.infoData.smallUnit,
        smallUnit: this.infoData.smallUnit,
        smallUnitNumber: differenceAmount,
        conversionRate: this.infoData.conversionRate,
      };
      return unitNumber.calculate(differenceParams);
    },
    bigunitAmountChange(currentValue, oldValue = 0, index) {
      const oldTotalAmount = this.calculateOldTotal();
      const newTotalParams = this.calculateNewTotal();
      const differenceAmount = oldTotalAmount - newTotalParams;
      const differenceParameter = this.differenceCalc(differenceAmount);
      if (differenceAmount < 0) {
        this.$nextTick(() => {
          this.infoData.adjustInfos[index].bigUnitAmount = oldValue;
        });
      } else {
        this.infoData.adjustInfos[0].smallUnitAvailableAmount = differenceParameter.smallUnitNumber;
        this.infoData.adjustInfos[0].bigUnitAvailableAmount = differenceParameter.bigUnitNumber;
      }
    },
    smallunitAmountChange(currentValue, oldValue = 0, index) {
      const oldTotalAmount = this.calculateOldTotal();
      const newTotalParams = this.calculateNewTotal();
      const differenceAmount = oldTotalAmount - newTotalParams;
      const differenceParameter = this.differenceCalc(differenceAmount);
      if (differenceAmount < 0) {
        this.$nextTick(() => {
          this.infoData.adjustInfos[index].smallUnitAmount = oldValue;
        });
      } else {
        this.infoData.adjustInfos[0].smallUnitAvailableAmount = differenceParameter.smallUnitNumber;
        this.infoData.adjustInfos[0].bigUnitAvailableAmount = differenceParameter.bigUnitNumber;
      }
    },
    auxiliaryAmountChange(currentValue, oldValue = 0, index) {
      const oldTotalAmount = this.infoData.auxiliaryAmount;
      const newTotalParams = this.calculateAuxiliaryTotal();
      const differenceAmount = oldTotalAmount - newTotalParams;
      if (differenceAmount < 0) {
        this.$nextTick(() => {
          this.infoData.adjustInfos[index].auxiliaryAmount = oldValue;
        });
      } else {
        this.infoData.adjustInfos[0].auxiliaryAmount = differenceAmount;
      }
    },

    // 批次调整时生产日期和到期日期根据保质期动态计算
    // 当货品的批属性有到期日期，没有生产日期时，自动根据到期日期-保质期计算出生产日期
    calculateProductDate({ expireDate }) {
      // 生产日期 = 到期日期 - 保质期
      return moment(expireDate).subtract(this.infoData.shelfLife, 'days').format(YMD);
    },
    checkProductDate(index) {
      const row = this.infoData.adjustInfos[index];
      if (!row.expire_date) return;
      row.productDate = this.calculateProductDate({
        expireDate: row.expire_date,
      });
    },
    // 当货品的批属性有生产日期，没有到期日期时，自动根据生产日期+保质期计算出到期日期
    calculateExpireDate({ productDate }) {
      // 到期日期 = 生产日期 + 保质期
      return moment(productDate).add(this.infoData.shelfLife, 'days').format(YMD);
    },
    checkExpireDate(index) {
      const row = this.infoData.adjustInfos[index];
      if (!row.productDate) return;
      row.expire_date = this.calculateExpireDate({
        productDate: row.productDate,
      });
    },
    handleTableChange(index, item) {
      if (!this.infoData.shelfLife) return; // 保质期
      if (item.prop === 'expire_date') {
        // 如果到期日期改变,检查生产日期
        this.checkProductDate(index);
      }
      if (item.prop === 'productDate') {
        // 如果生产日期改变,检查到期日期
        this.checkExpireDate(index);
      }
    },
    handleDisbaledDate(date, index, item) {
      const today = moment();
      if (item.prop === 'expire_date') {
        // 不存在保质期 不限制
        if (!this.infoData.shelfLife) return false;
        const maxDate = moment(today).add(this.infoData.shelfLife, 'days').format(YMD);
        // 到期日期
        return moment(maxDate).isBefore(date.getTime());
      }
      if (item.prop === 'productDate') {
        // 生产日期
        return moment(today).isSameOrBefore(date.getTime());
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.adjustment {
  .content-box {
    padding: 10px 20px;

    .el-col {
      margin: 10px 0;
    }
  }

  .radio-title {
    :deep(.el-radio__inner) {
      width: 18px;
      height: 18px;
    }

    :deep(.el-radio__label) {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .amount-box {
    width: 65px;
  }

  .unit-box {
    margin: 0 10px;
  }

  .table-input {
    display: flex;
    align-items: center;
  }
}
</style>
