import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import unitNumber from '@/utils/unitNumber';

export const TABLECOLUMN = (cols = []) => {
  const attr = cols.map((item) => ({
    label: `批属性-${item.fieldName}`,
    prop: `batch_${item.humpFieldCode}`,
    exportProp: item.humpFieldCode,
    width: 180,
    formatter: (row) => row[item.humpFieldCode],
  }));
  return [{
    type: 'selection',
    width: 50,
    prop: 'selection',
  }, {
    label: '序号',
    type: 'index',
    width: 50,
    prop: 'index',
  }, {
    label: '货品编码',
    prop: 'goodsCode',
    minWidth: 180,
  }, {
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    minWidth: 180,
  }, {
    label: '商品名称',
    prop: 'goodsName',
    minWidth: 120,
  }, {
    label: '货主',
    prop: 'shipperName',
    minWidth: 100,
  }, {
    label: '温层',
    prop: 'goodsTemperatureLayerName',
    minWidth: 80,
  }, {
    label: '批次号',
    prop: 'batchNo',
    minWidth: 100,
  },
  ...attr,
  {
    label: '库位',
    prop: 'locationCode',
    minWidth: 130,
  }, {
    label: '容器',
    prop: 'containerCode',
    minWidth: 100,
  }, {
    label: 'LPN',
    prop: 'lpn',
    minWidth: 130,
  }, {
    label: '货品质量',
    prop: 'goodsQuality',
    minWidth: 120,
  }, {
    label: '默认单位',
    prop: 'defaultUnit',
    minWidth: 80,
  }, {
    label: '规格',
    prop: 'goodsSpecifications',
    minWidth: 80,
  }, {
    label: '换算率',
    prop: 'conversionRate',
    minWidth: 80,
  }, {
    label: '总库存(默认单位)',
    prop: 'defaultUnitAmount',
    minWidth: 160,
  }, {
    label: '整单位数量',
    prop: 'totalBigUnitNum',
    minWidth: 140,
    formatter: (row) => row.totalBigUnitNum + row.bigUnit,
  }, {
    label: '零单位数量',
    prop: 'totalSmallUnitNum',
    minWidth: 140,
    formatter: (row) => row.totalSmallUnitNum + row.smallUnit,
  }, {
    label: '可用量',
    prop: 'availableNum',
    minWidth: 140,
    formatter: (row) => (row.availableNum
      ? unitNumber.calculate({ ...row, smallUnitNumber: row.availableNum }).txt : row.availableNum),

  }, {
    label: '锁定量',
    prop: 'lockedNum',
    minWidth: 140,
    formatter: (row) => (row.lockedNum
      ? unitNumber.calculate({ ...row, smallUnitNumber: row.lockedNum }).txt : row.lockedNum),

  }, {
    label: '冻结量',
    prop: 'frozenNum',
    minWidth: 140,
    formatter: (row) => (row.frozenNum
      ? unitNumber.calculate({ ...row, smallUnitNumber: row.frozenNum }).txt : row.frozenNum),

  }, {
    label: '辅助数量',
    prop: 'auxiliaryAmount',
    minWidth: 140,
  }, {
    label: '辅助单位',
    prop: 'auxiliaryUnit',
    minWidth: 80,
  }, {
    label: '生产日期',
    prop: 'productDate',
    ...DateTime,
  }, {
    label: '到期日期',
    prop: 'stockExpireDate',
    ...DateTime,
  }, {
    label: '保质期(天)',
    prop: 'shelfLife',
    minWidth: 120,
  }, {
    label: '库龄',
    prop: 'stockAge',
    minWidth: 80,
  },
  {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    minWidth: 160,
  },
  ];
};
export const serchFields = (GOODS_QUALITY, zoneTemperature) => [{
  label: '货品编码',
  prop: 'goodsCode',
  component: FormType.INPUT,
}, {
  label: '商品名称',
  prop: 'goodsName',
  component: FormType.INPUT,
}, {
  label: '货主',
  prop: 'shipperId',
}, {
  label: '库区',
  prop: 'warehouseAreaId',
}, {
  label: '库位',
  prop: 'locationCode',
  component: FormType.INPUT,
}, {
  label: '容器号',
  prop: 'containerCode',
  component: FormType.INPUT,
}, {
  label: '货品质量',
  prop: 'goodsQuality',
  component: FormType.SELECT,
  options: GOODS_QUALITY,
},
{
  label: '批次',
  prop: 'batchNo',
  component: FormType.INPUT,
}, {
  prop: 'labelSelect',
}, {
  label: '温层',
  prop: 'goodsTemperatureLayer',
  component: FormType.SELECT,
  options: zoneTemperature,
},
];
