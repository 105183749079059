const TABLECOLUMN = [
  {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    minWidth: 120,
  }, {
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    minWidth: 180,
  }, {
    label: '货主',
    prop: 'shipperName',
    minWidth: 100,
  }, {
    label: '温层',
    prop: 'temperatureLayerName',
    minWidth: 100,
  }, {
    label: '库位',
    prop: 'locationCode',
    minWidth: 130,
  }, {
    label: '货品质量',
    prop: 'goodsQuality',
    minWidth: 120,
  }, {
    label: '容器',
    prop: 'containerCode',
    minWidth: 100,
  }, {
    label: '批次号',
    prop: 'batchNo',
    minWidth: 100,
  }, {
    label: '库存数量',
    prop: 'totalAmountStr',
    minWidth: 140,
  },
  {
    label: '可移库数量',
    prop: 'availableAmount',
    minWidth: 140,
  },
  {
    label: '目标库位',
    prop: 'targetLocationCode',
    fixed: 'right',
    minWidth: 140,
  },
  {
    label: '目标容器',
    prop: 'targetContainerCode',
    fixed: 'right',
    minWidth: 140,
  },
  {
    label: '移库数量',
    prop: 'transferAmount',
    fixed: 'right',
    minWidth: 240,
  },
  {
    label: '辅助单位数量',
    prop: 'auxiliaryUnitTransferAmount',
    fixed: 'right',
    minWidth: 140,
  }, {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    width: 120,
  },
];

export default TABLECOLUMN;
