export const ATTRIBUTE_TYPE = {
  INPUT: 'input',
  DATE: 'date',
};
export const STEPS_NUM = {
  the_same: 1,
  not_same: 0.001,
};

export const PRECISION_NUM = {
  the_same: 0,
  not_same: 3,
};
