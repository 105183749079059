import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:容器库存分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15199
 */
export function containerStockPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/container_stock/page', data, {
    params,
  });
}

/**
 * @description:容器库存导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15201
 */
export function containerStockExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/stock/container_stock/export', data, { fileName: '容器库存列表', extension: 'xlsx' });
}

/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}

/**
 * @description: 批次字段列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/385/interface/api/12622
 *
 */
export function batchFieldList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/batch_field/list', data, {});
}

/**
 * @description: 新增库存调整单
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15837
 *
 */
export function stockAdjustCreate(data) {
  return wmsPlusHttp.post('/warehouse_management_system/adjust_order/create', data, {});
}

/**
 * @description: 一键生成移库单
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15795
 *
 */
export function stockTransferOrder(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_order/generate', data, {});
}

/**
 * @description: 根据库存获取货品的批属性规则和值
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15943
 *
 */
export function getBatchAttribute(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_batch_attribute/rule_and_value', data, {});
}

/**
 * @description: 解冻库存
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17527
 *
 */
export function searchContainerStockLocked(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/container_stock/locked/query', data);
}
/**
 * @description: 打印标签根据容器库存
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20693
 *
 */
export function byContainerStock(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/print/label/by_container_stock', data);
}
