<template>
  <div class="adjustment">
    <nh-dialog
      v-model="dialogVisible"
      title="一键移库"
      custom-class="custom-dialog_1200"
      :track="{
        name_zh: `在库管理/库存管理/容器库存查询/弹窗-一键移库`,
        trackName: $route.path
      }"
      @close="handleBeforeClose"
    >
      <PlTable
        :data="tableData"
        :columns="TABLECOLUMN"
      >
        <template #targetLocationCode="{row}">
          <nh-remote-select
            v-model="row.targetLocationCode"

            clearable
            :remote-method="(query)=>{remoteLocation(query, row)}"
            :loading="loading.remoteLocation"
          >
            <el-option
              v-for="item in row.storehouseList"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            />
          </nh-remote-select>
        </template>
        <template #targetContainerCode="{row}">
          <nh-remote-select
            v-model="row.targetContainerCode"
            :remote-method="(query)=>{remoteContainerCode(query, row)}"
            :loading="loading.remoteContainerCode"
          >
            <el-option
              v-for="item in row.containerCodeList"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            />
          </nh-remote-select>
        </template>
        <template #transferAmount="{row}">
          <el-input-number
            v-model="row.bigUnitTransferAmount"
            :min="0"
            size="small"
            class="amount-box"
            step-strictly
            :controls="false"
            :step="row.bigUnit === row.smallUnit?STEPS_NUM.the_same:STEPS_NUM.not_same"
            :precision="row.bigUnit === row.smallUnit? 0 : 3"
          />
          <span class="unit-box">{{ row.bigUnit }}</span>
          <el-input-number
            v-model="row.smallUnitTransferAmount"
            :min="0"
            class="amount-box"
            size="small"
            step-strictly
            :controls="false"
            :step="0.001"
            :precision="3"
          />
          <span class="unit-box">{{ row.smallUnit }}</span>
        </template>
        <template #auxiliaryUnitTransferAmount="{row}">
          <el-input-number
            v-model="row.auxiliaryUnitTransferAmount"
            :min="0"
            size="small"
            :disabled="!row.auxiliaryUnit"
            class="amount-box"
            step-strictly
            :controls="false"
          />
          <span class="unit-box">{{ row.auxiliaryUnit }}</span>
        </template>
        <template #action="{row}">
          <nh-button type="text" @click="handleDelete(row)">
            删除
          </nh-button>
        </template>
      </PlTable>
      <template #footer>
        <nh-button
          type="primary"
          @click="handleClose"
        >
          取 消
        </nh-button>
        <nh-button
          type="primary"
          :loading="loading.handleSubmit"
          @click="handleSubmit"
        >
          确 定
        </nh-button>
      </template>
    </nh-dialog>
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';
import { storehousePage } from '@/views/base/warehouse-about/warehouse-storehouse/api';
import { warehouseContainerPage } from '@/views/base/warehouse-about/container/api';
import TABLECOLUMN from './fileds';
import { stockTransferOrder } from '../api';
import { PRECISION_NUM, STEPS_NUM } from '../constant';

export default {
  mixins: [loadingMixin],
  emits: ['submitSuccess'],
  data() {
    return {
      TABLECOLUMN,
      PRECISION_NUM,
      STEPS_NUM,
      dialogVisible: false,
      tableData: [],
      loading: {
        remoteLocation: false,
        remoteContainerCode: false,
        handleSubmit: false,
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    init(list) {
      this.dialogVisible = true;
      this.tableData = JSON.parse(JSON.stringify(list))?.map((item) => (
        {
          ...item,
          targetLocationCode: item.targetLocationCode || null,
          targetContainerCode: item.targetLocationCode || null,
        }
      )) || [];
    },

    handleDelete(row) {
      const index = this.tableData.indexOf(row);
      if (index !== -1) {
        this.tableData.splice(index, 1);
      }
    },
    handleBeforeClose() {
      this.tableData = [];
    },
    buildParams() {
      const data = this.tableData.map(({
        id,
        goodsId,
        conversionRate,
        targetLocationCode,
        targetContainerCode,
        bigUnitTransferAmount,
        smallUnitTransferAmount,
        auxiliaryUnitTransferAmount,
        bigUnit,
        smallUnit,
      }) => {
        const reservedDigits = bigUnit === smallUnit
          ? this.PRECISION_NUM.the_same : this.PRECISION_NUM.not_same;
        return {
          stockId: id,
          goodsId,
          conversionRate,
          targetLocationCode,
          targetContainerCode,
          bigUnitTransferAmount: bigUnitTransferAmount ? bigUnitTransferAmount.toFixed(reservedDigits) : '',
          smallUnitTransferAmount: smallUnitTransferAmount ? smallUnitTransferAmount.toFixed(3) : '',
          auxiliaryUnitTransferAmount: auxiliaryUnitTransferAmount ? auxiliaryUnitTransferAmount.toFixed(3) : '',
        };
      });
      return data;
    },
    async handleSubmit() {
      const data = this.buildParams();
      await stockTransferOrder({ details: data });
      this.$message({ type: 'success', message: '移库单生成成功' });
      this.handleClose();
      this.$emit('submitSuccess');
    },
    async remoteLocation(query, row) {
      const result = await storehousePage({ storageLocationCode: query });
      const current = row;
      current.storehouseList = result.records.map(({ storageLocationCode }) => ({
        value: storageLocationCode,
        label: storageLocationCode,
      }));
    },
    async remoteContainerCode(query, row) {
      const result = await warehouseContainerPage({ containerCode: query });
      const current = row;
      current.containerCodeList = result.records.map(({ containerCode }) => ({
        value: containerCode,
        label: containerCode,
      }));
    },
  },
};
</script>
<style lang="scss" scoped>

.amount-box {
  width: 65px;
}

.unit-box {
  margin: 0 10px;
}
</style>
