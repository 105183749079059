import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:库位批次库存分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15123
 */
export function shipperGoodsStockPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/batch_stock/page', data, {
    params,
  });
}

/**
 * @description:库位批次库存导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15125
 */
export function shipperGoodsStockExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/stock/batch_stock/export', data, { fileName: '库位批次库存', extension: 'xlsx' });
}

/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}

/**
 * @description: 批次字段列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/385/interface/api/12622
 *
 */
export function batchFieldList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/batch_field/list', data, {});
}

/**
 * @description: 冻结库存
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15119
 *
 */
export function stockFreeze(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/freeze', data, {});
}

/**
 * @description: 解冻库存
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15121
 *
 */
export function stockUnFreeze(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/unfreeze', data, {});
}

/**
 * @description: 解冻库存
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17525
 *
 */
export function searchBatchStockLocked(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/batch_stock/locked/query', data, {});
}

/**
 * @description: 货品品质调整
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18067
 *
 */
export function adjustStockQuality(data) {
  return wmsPlusHttp.post('/warehouse_management_system/adjust_order/quality_adjust', data, {});
}
/**
 * @description: 批次调整，批次库存查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20499
 *
 */
export function batchStockQuery(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/batch_stock/query', data, {});
}
/**
 * @description: 根据货品来获取批属性规则
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20613
 *
 */
export function batchAttributeByGoods(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_batch_attribute/get_by_goods', data, {});
}
/**
 * @description: 历史批次查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20459
 *
 */
export function historyBatchuery(data) {
  return wmsPlusHttp.post('/warehouse_management_system/batch_attribute/history/batch/query', data, {});
}
/**
 * @description: 执行调整批次
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20685
 *
 */
export function adjustmentConfirm(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/batch_stock/adjustment/confirm', data, {});
}
/**
 * @description: 列表显示自定义字段接口(驼峰)
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20687
 *
 */
export function customerShowList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/batch_field/customer/show/list', data, {});
}
