import { wmsPlusHttp } from '@/utils/http/index';
/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}
/**
 * @description: 库位管理 新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11168
 */

export function storehouseInsert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/insert', data);
}
/**
 * @description: 库位管理 批量新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12012
 */
export function getPstorehouseBatchInsertdaPage(data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/batch/insert', data);
}
/**
 * @description: 批量生成库位
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12014
 */
export function storehouseGenerate(data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/generate', data);
}
/**
 * @description: 库位管理 修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11174
 */
export function storehouseUpdate(data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/update', data);
}
/**
 * @description: 库位管理 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11176
 */
export function storehouseGet(params) {
  const { id } = { ...params };
  return wmsPlusHttp.get(`/warehouse_foundation/storehouse/get?id=${id}`);
}
/**
 * @description: 库位管理 根据ID删除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11178
 */
export function storehouseDelete(params) {
  return wmsPlusHttp.get('/warehouse_foundation/storehouse/delete', null, { params });
}
/**
 * @description: 库位管理 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11180
 */
export function storehousePage(data, params = {
  page: 1,
  size: 10,
}) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/page', data, {
    params,
  });
}
/**
 * @description: 启用禁用库位
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11182
 */
export function storehouseEnable(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/enable', data, { params });
}
/**
 * @description: 导出库位列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11268
 */
export function exportStorehouse(data) {
  return wmsPlusHttp.download('/warehouse_foundation/storehouse/export/list', data, { fileName: '库位列表', extension: 'xlsx' });
}

/**
 * @description: 导入
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12030
 */
export function uploadStation(data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/import', data, { });
}

/**
 * @description: 下载模板
 * @api文档：
 *
 */
export function downLoadTemplate(data) {
  return wmsPlusHttp.download('/warehouse_management_system/file/template/download', data, { fileName: '库位导入模板', extension: 'xlsx' });
}
