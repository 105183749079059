// 任务状态
export const TaskStatus = {
  PREPARE: 'PREPARE',
  DOING: 'DOING',
  FINISHED: 'FINISHED',
  CLOSE: 'CLOSE',
};

export const TaskStatusText = {
  [TaskStatus.PREPARE]: '准备中',
  [TaskStatus.DOING]: '作业中',
  [TaskStatus.FINISHED]: '已完结',
  [TaskStatus.CLOSE]: '已关闭',
};

// 任务类型
export const TaskType = {
  PICKING: 'PICKING', // 出库-拣货
  PART_PICKING: 'PART_PICKING', // 出库-分拣
  RECHECKED: 'RECHECKED', // 出库-复核
  GATHER: 'GATHER', // 出库-集货
  LOADING: 'LOADING', // 出库-装车
  TRANSPORT: 'TRANSPORT', // 出库--发运
  MOVE: 'MOVE', // 出库-移动
  ACCEPTANCE: 'ACCEPTANCE', // 入库-验货
  UPPER_SHELF: 'UPPER_SHELF', // 入库-上架
  WAREHOUSING: 'WAREHOUSING', // 入库-入库
  TRANSFER_DOWN: 'TRANSFER_DOWN', // 移库移位-下货
  TRANSFER_UP: 'TRANSFER_UP', // 移库移位-上货
  COUNT: 'COUNT', // 盘点
  RECOUNT: 'RECOUNT', // 双人盘点
  TRANSFER_DIRECT: 'TRANSFER_DIRECT', // 直接移库
  DIFFERENCE_MOVE: 'DIFFERENCE_MOVE', // 差异回库
};

export const TaskTypeText = {
  [TaskType.PICKING]: '出库-拣货', // 出库-拣货
  [TaskType.PART_PICKING]: '出库-分拣', // 出库-分拣
  [TaskType.RECHECKED]: '出库-复核', // 出库-复核
  [TaskType.GATHER]: '出库-集货', // 出库-集货
  [TaskType.LOADING]: '出库-装车', // 出库-装车
  [TaskType.TRANSPORT]: '出库-发运', // 出库--发运
  [TaskType.MOVE]: '出库-移动', // 出库-移动
  [TaskType.ACCEPTANCE]: '入库-验货', // 入库-验货
  [TaskType.UPPER_SHELF]: '入库-上架', // 入库-上架
  [TaskType.WAREHOUSING]: '入库-入库', // 入库-入库
  [TaskType.TRANSFER_DOWN]: '移库移位-下货', // 移库移位-下货
  [TaskType.TRANSFER_UP]: '移库移位-上货', // 移库移位-上货
  [TaskType.COUNT]: '盘点', // 盘点
  [TaskType.RECOUNT]: '双人盘点', // 双人盘点
  [TaskType.TRANSFER_DIRECT]: '直接移库', // 直接移库
};

export const TaskTypeTextOptions = {
  [TaskType.PICKING]: '出库-拣货', // 出库-拣货
  [TaskType.PART_PICKING]: '出库-分拣', // 出库-分拣
  [TaskType.RECHECKED]: '出库-复核', // 出库-复核
  [TaskType.GATHER]: '出库-集货', // 出库-集货
  [TaskType.LOADING]: '出库-装车', // 出库-装车
  [TaskType.TRANSPORT]: '出库-发运', // 出库--发运
  [TaskType.MOVE]: '出库-移动', // 出库-移动
  [TaskType.ACCEPTANCE]: '入库-验货', // 入库-验货
  [TaskType.UPPER_SHELF]: '入库-上架', // 入库-上架
  [TaskType.WAREHOUSING]: '入库-入库', // 入库-入库
  [TaskType.TRANSFER_DOWN]: '移库移位-下货', // 移库移位-下货
  [TaskType.TRANSFER_UP]: '移库移位-上货', // 移库移位-上货
  [TaskType.COUNT]: '盘点', // 盘点
  [TaskType.RECOUNT]: '双人盘点', // 双人盘点
  [TaskType.TRANSFER_DIRECT]: '直接移库', // 直接移库
  [TaskType.DIFFERENCE_MOVE]: '差异回库', // 差异回库
};

// 单据类型
export const BillType = {
  STORE: 'STORE',
  MOVE: 'MOVE',
  COUNT: 'COUNT',
  WAVE: 'WAVE',
  TRANSFER: 'TRANSFER',
};

export const BillTypeText = {
  [BillType.STORE]: '入库',
  [BillType.MOVE]: '移动',
  [BillType.COUNT]: '盘点',
  [BillType.WAVE]: '波次',
  [BillType.TRANSFER]: '移库移位',
};

// 入库单类型
export const StoreBillType = {
  ORDER: 'ORDER',
  RETURNED: 'RETURNED',
  REJECTED: 'REJECTED',
  TRANSFER: 'TRANSFER',
  SETUP: 'SETUP',
  VIRTUAL: 'VIRTUAL',
  THROUGH: 'THROUGH',
  OTHER: 'OTHER',
};

export const StoreBillTypeText = {
  [StoreBillType.ORDER]: '订单入库',
  [StoreBillType.RETURNED]: '客退入库',
  [StoreBillType.REJECTED]: '拒收入库',
  [StoreBillType.TRANSFER]: '调拨入库',
  [StoreBillType.SETUP]: '期初建账',
  [StoreBillType.VIRTUAL]: '虚拟入库',
  [StoreBillType.THROUGH]: '经由品入库',
  [StoreBillType.OTHER]: '其它入库',
};
